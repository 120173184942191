import React, {useRef} from 'react'
import { Layout, theme, Alert, Tabs, Button, message } from 'antd'
import HeaderScreen from '../components/common/HeaderScreen'
import { Content } from 'antd/es/layout/layout'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { PATH } from '../routes/CustomRoutes';
import PlantationInfoView from '../components/farmer/PlantationInfoView';
import PlantationLocationView from '../components/farmer/PlantationLocationView';
import PlantationAreaView from '../components/farmer/PlantationAreaView';
import PlantationLatexView from '../components/farmer/PlantationLatexView';
import AddJsonModal from '../components/common/AddJsonModal';
import { DELETE, GET, PATCH, POST } from '../frameworks/HttpClient';
import { useWindowDimensionContext } from '../hooks/WindowDimensionProvider';
import { URL_PLANTATIONS } from '../constants/urls';
import { PLANTATION_DOCUMENT_TYPE, IMPORT_JSON_TYPE } from '../constants/string';
import { BG_LAYOUT_COLOR, DARK_GRAY_COLOR } from '../constants/color';
import { AimOutlined, GlobalOutlined , FileOutlined, FileSearchOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';



export default function PlantationDetailScreen(props) {
  const navigate = useNavigate();
  const infoRef = useRef(null);
  const locationRef = useRef(null);
  const areaRef = useRef(null);
  const latexRef = useRef(null);
  const params = useParams();
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('sm')
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [searchParams, __] = useSearchParams();
  const [activeKey, setActiveKey] = React.useState("INFO");
  const [loading, setLoading] = React.useState(false);
  const [plantData, setPlantData] = React.useState(null);
  const [polygonLayer, setPolygonLayer] = React.useState([]);
  const [deedDocument, setDeedDocument] = React.useState(null);
  const [rentalDocument, setRentalDocument] = React.useState(null);
  const [invoiceDocument, setInvoiceDocument] = React.useState(null);
  const [proxyDocument, setProxyDocument] = React.useState(null);
  const [additionalDocument, setAdditionalDocument] = React.useState(null);
  const [woodType, setWoodType] = React.useState(null);
  const [animalType, setAnimalType] = React.useState(null);
  const [showLabelSpace, setShowLabelSpace] = React.useState(null);
  const [showAddJsonModal, setShowAddJsonModal] = React.useState(false);
  const [updateLog, setUpdateLog] = React.useState(null);
  
  const [traceability, setTraceability] = React.useState(searchParams.get('type') ? searchParams.get('type') : "");

  const { t, i18n } = useTranslation();

  const {
		token: { colorWarning, colorBgContainer },
	} = theme.useToken();

  const onTabsChange = (key) => setActiveKey(key);

  // Fetch detail and documentsx
  const fetchData = async () => {
    if (params.plantId){
      setErrorMessages(null)
      try {
        // Fetch plant datail
        const response = await GET(`${URL_PLANTATIONS.PLANTATIONS}${params.plantId}`)
        setPlantData(response.data)
        setShowLabelSpace(response.data.area_rai)
        if(response.data.traceability){
          setTraceability(response.data.traceability)
        }

        if (response.data.recent_update_log != null) {
          setUpdateLog(response.data.recent_update_log)
        }
      } catch (error) {
        setErrorMessages(error.errorMessages);
      }

      // Fetch plant document
      fetchDocuments(params.plantId);

      // Fetch polygon layer
      fetchPolygons(params.plantId);

      fetchArea(params.plantId)
    }
  }

  const fetchArea = async (plantId) => {
    if (!plantId) {
      setErrorMessages("Plant ID not found!");
      return;
    }
  }

  // Fetch document list
  const fetchDocuments = async (plantId) => {
    if (!plantId) {
      setErrorMessages("Plant ID not found!");
      return;
    }

    try {
      const wood_type = await GET(`${URL_PLANTATIONS.WOOD_TREE}?plantation=${plantId}`)
      setWoodType(wood_type.data.results)

      const animal_type = await GET(`${URL_PLANTATIONS.ANIMAL_TYPE}?plantation=${plantId}`)
      setAnimalType(animal_type.data.results)

    } catch (error) {
      setErrorMessages(error.errorMessages);
    }

    setErrorMessages(null);
    try {
      let documentData = [];
      const document = await GET(`${URL_PLANTATIONS.DOCUMENT}?plantation=${plantId}`)
      document.data.results.map(data => documentData.push({...data, url: data.document}));
      setDeedDocument(documentData.filter(doc => doc.type === PLANTATION_DOCUMENT_TYPE.DEED))
      setRentalDocument(documentData.filter(doc => doc.type === PLANTATION_DOCUMENT_TYPE.RENTAL))
      setInvoiceDocument(documentData.filter(doc => doc.type === PLANTATION_DOCUMENT_TYPE.INVOICE))
      setProxyDocument(documentData.filter(doc => doc.type === PLANTATION_DOCUMENT_TYPE.PROXY))
      setAdditionalDocument(documentData.filter(doc => doc.type === PLANTATION_DOCUMENT_TYPE.ADDITIONAL))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  // Cut of null value
  const filterEmptyOut = (jsonInput) => {
    const entries = Object.entries(jsonInput)
    const nonEmptyOrNull = entries.filter(([ _, val]) => val !== "" && val !== null && val != undefined)
    return Object.fromEntries(nonEmptyOrNull)
  }

  const fetchPolygons = async (plantId) => {
    if (!plantId) {
      setErrorMessages("Plant ID not found!");
      return;
    }

    setErrorMessages(null);
    try {
      const polygon = await GET(`${URL_PLANTATIONS.AREA_LAYER}?plantation=${plantId}`)
      setPolygonLayer(polygon.data.results);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  // Handle Register Button
  const handleRegisterPlantations = async (action) => {
    setLoading(true);
    setErrorMessages(null)
    let plantId = plantData ? plantData.id : null;
    try {
      // prepare data
      let dataInfo = {}
      let dataLocation = {}
      let dataArea = {}
      let dataLatex = {}
      let deletedPolygon = [];
      // Get data from information tab
      if (infoRef.current) {
        dataInfo = await infoRef.current.getData();
        if(dataInfo)
          dataInfo = filterEmptyOut(dataInfo);
      }
      // Get data from location tab
      if (locationRef.current) {
        dataLocation = await locationRef.current.getData();
        deletedPolygon = await locationRef.current.getDeletedPolygon();
        if(dataLocation) {
          dataLocation = filterEmptyOut(dataLocation);
          if(!dataLocation['point']){
            dataLocation['point'] = null;
          }
        }
      }

      // Get data from area tab
      if (areaRef.current) {
        dataArea = await areaRef.current.getData();
        if(dataArea)
          dataArea = filterEmptyOut(dataArea);
          
      }
      // Get data from latex tab
      if (latexRef.current) {
        dataLatex = await latexRef.current.getData();
        if(dataLatex)
          dataLatex = filterEmptyOut(dataLatex);
      }

      let data = {...dataInfo, ...dataLocation, ...dataArea, ...dataLatex}
      
      if(traceability && traceability !== "null")
        data['traceability'] = traceability

      // POST DATA
      if (plantId) {
        const response = await PATCH(`${URL_PLANTATIONS.PLANTATIONS}${plantId}/`, data)
        setPlantData(response.data)
      } else {
        data['farmer'] = params.id
        const response = await POST(URL_PLANTATIONS.PLANTATIONS, data)
        plantId = response.data.id
        setPlantData(response.data)
      }

      // Upload file
      await UploadFile(data.deed_document, PLANTATION_DOCUMENT_TYPE.DEED, plantId);
      await UploadFile(data.rental_document, PLANTATION_DOCUMENT_TYPE.RENTAL, plantId);
      await UploadFile(data.invoice_document, PLANTATION_DOCUMENT_TYPE.RENTAL, plantId);
      await UploadFile(data.proxy_document, PLANTATION_DOCUMENT_TYPE.PROXY, plantId);
      await UploadFile(data.additional_document, PLANTATION_DOCUMENT_TYPE.ADDITIONAL, plantId);

      if(plantId) 
        await fetchDocuments(plantId)
      
      if(data.polygon && plantId) {
        await HandlePolygon(data.polygon, plantId);
      }
      
      if(deletedPolygon)
        await HandlePolygonDeleted(deletedPolygon);

      if(data.wood_type && plantId)
        await HandleWoodType(data.wood_type, plantId)

      if(data.animal_type && plantId)
        await HandleAnimalType(data.animal_type, plantId)

      // Handle register state
      if(action === 'register' || action === 'edit' && plantId){
        await POST(`${URL_PLANTATIONS.PLANTATIONS}${plantId}/submit/`)
        navigate(`${PATH.FARMER_PLANTATIONS}/${params.id}`)
      }

      if(plantId) {
        fetchPolygons(plantId)
      }

      message.open({
        type: 'success',
        content: t("data_saved_successfully"),
      });
    } catch (error) {
      setErrorMessages(error.errorMessages);
      console.log(error)
      return
    } finally {
      if(plantId){
        navigate(`${PATH.PLANTATION_DETAIL}/${params.id}/${plantId}`, { replace: true });
      }

      if(plantId && locationRef.current){
        locationRef.current.resetMap();
      } 
      setLoading(false)
    }
  }

  // Upload file to Document API
  const UploadFile = async(document, type, id) => {
    if(document != null) {
      await Promise.all(
        document.map(async (item) => {
          let file = {plantation: id, type: type, document: item.originFileObj}
          try {
            await POST(URL_PLANTATIONS.DOCUMENT, file, true)
          } catch (error) {
            console.log(error.errorMessages);
          }
        })
      )
    }
  }

  const HandlePolygon = async(poly, id) => {
    if(poly != null) {
      await Promise.all(
        poly.map(async (item) => {
          let data = {plantation: id, type: item.type, polygon : item.polygon}
          try {
            await POST(URL_PLANTATIONS.AREA_LAYER, data)
          } catch (error) {
            console.log(error.errorMessages);
          }
        })
      )
    }
  }

  const HandlePolygonDeleted = async(deletedID) => {
    if(deletedID.length > 0) {
      await Promise.all(
        deletedID.map(async (item) => {
          try {
          await DELETE(`${URL_PLANTATIONS.AREA_LAYER}${item}/`)
          } catch (error) {
            console.log(error.errorMessages);
          }
        })
      )
    }
  }

  const HandleWoodType = async(woodType, id) => {
    if(woodType != null) {
      await Promise.all(
        woodType.map(async (item) => {
          let data = {plantation: id, name: item.name, number_of_tree : item.number_of_tree}
          try {
            await POST(URL_PLANTATIONS.WOOD_TREE, data)
          } catch (error) {
            console.log(error.errorMessages);
          }
        })
      )
    }
  }

  const HandleAnimalType = async(animalType, id) => {
    if (animalType != null) {
      await Promise.all(
        animalType.map(async (item) => {
          let data = {plantation: id, name: item.name}
          try {
            await POST(URL_PLANTATIONS.ANIMAL_TYPE, data)
          } catch (error) {
            console.log(error.errorMessages);
          }
        })
      )
    }
  }

  React.useEffect(() => {
    fetchData();
  },[params.plantId])

  return (
    <Layout style={{ minHeight: "100vh", background: BG_LAYOUT_COLOR }}>
      <HeaderScreen title={<>{plantData ? `${plantData.farmer_name} ${plantData.code ? `(${plantData.code})` : ""}` : t("add_rubber_plantation_plot")}{`[${traceability.toUpperCase()}]`}</>} />

      <Content 
        style={{ 
          margin: "0px 24px 24px 24px", 
          padding: "16px 24px",
          textAlign: "left",  
          minHeight: 280,
          background: colorBgContainer }}>
        {errorMessages && 
          <Alert
            style={{marginBottom: 8}}
            message={errorMessages}
            type="error"
            showIcon
          />
        }

        {updateLog && updateLog.note != null && updateLog.note !== "" &&
          <Alert
            message={updateLog.note}
            type={updateLog.action === 'reject' ? 'warning' : 'info'}
            showIcon
          ></Alert>
        }

        <Tabs
          activeKey={activeKey}
          onChange={onTabsChange}
          size='large'
          items={[
            {
              key: "INFO", 
              label: <span><FileOutlined />{t("plot_information")}</span>,
              children: 
                <PlantationInfoView 
                  ref={infoRef}
                  data={plantData} 
                  deed_document={deedDocument} 
                  rental_document={rentalDocument} 
                  invoice_document={invoiceDocument}
                  proxy_document={proxyDocument}
                  setShowLabelSpace={(space) => setShowLabelSpace(space)} />
            },
            {
              key: "LOCATION", 
              label: <span><AimOutlined />{t("rubber_plantation_location")}</span>,
              children: <PlantationLocationView ref={locationRef} additional_document={additionalDocument} data={plantData} polygonLayer={polygonLayer} />
            },
            {
              key: "AREA", 
              label: <span><GlobalOutlined />{t("area_characteristics")}</span>,
              children: <PlantationAreaView ref={areaRef} data={plantData} animal={animalType} wood={woodType} />
            },
            {
              key: "LATEX", 
              label: <span><FileSearchOutlined/>{t("rubber_information")}</span>, 
              children: <PlantationLatexView ref={latexRef} data={plantData} space={showLabelSpace}/>
            }
          ]} />

          {(!plantData || (plantData && (plantData.status === "draft"))) && (
            <div style={{textAlign: "center", marginTop: 24, marginBottom: 24}}>
              {!isOnMobile && 
                <Button
                  type={"primary"}
                  shape={"round"}
                  style={{ borderColor: DARK_GRAY_COLOR, background: DARK_GRAY_COLOR, marginRight: 16 }}
                  onClick={() => setShowAddJsonModal(true)}>
                  Import JSON
                </Button>
              }
              <Button 
                loading={loading}             
                type={"primary"}
                shape={"round"} 
                style={{background: colorWarning, marginRight: 16}}
                onClick={() => handleRegisterPlantations('draft')}>
                  {t("farmer_status.draft")}
              </Button>
              <Button 
                loading={loading} 
                type={"primary"}
                shape={"round"} 
                onClick={() => handleRegisterPlantations('register')}>
                  {t("register.register")}
              </Button>
            </div>
          )}

          {(plantData && (plantData.status !== "draft")) && (
            <div style={{textAlign: "center", marginTop: 24, marginBottom: 24}}>
              {!isOnMobile && 
                <Button
                  type={"primary"}
                  shape={"round"}
                  style={{ borderColor: DARK_GRAY_COLOR, background: DARK_GRAY_COLOR, marginRight: 16 }}
                  onClick={() => setShowAddJsonModal(true)}>
                  Import JSON
                </Button>
              }
              <Button 
                loading={loading} 
                type={"primary"}
                shape={"round"} 
                style={{background: colorWarning}}
                onClick={() => handleRegisterPlantations('edit')}>
                  {t("edit_Data")}
              </Button>
            </div>
          )}

        <AddJsonModal
          title={t("import_plot_data_from_json")}
          open={showAddJsonModal}
          onClose={() => setShowAddJsonModal(false)}
          importType={IMPORT_JSON_TYPE.PLANTATION}
          onImported={(data) => {
            plantData ? setPlantData({...plantData, ...data}) : setPlantData(data)
          }} />
      </Content>
    </Layout>
  )
}
