import React from 'react';
import { Card, Col, Row, message} from 'antd';
import { ManOutlined, WomanOutlined  } from '@ant-design/icons';
import { Pie,Bar,Doughnut} from 'react-chartjs-2';
import StaticCard from '../components/common/StaticCard';
import { GET } from '../frameworks/HttpClient';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../hooks/UserSettingProvider';
import { AUBBER_AGE, FARMER_FOCUS, FARMER_TYPE } from '../constants/string';
import { URL_DASHBOARD } from '../constants/urls';

export default function FactoryDashboardView() {
  const [plantation, setPlantation] = React.useState([]);
  const [farmer,setFarmer] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const { user } = useUserContext();
  const id = user.factory;
  const { t } = useTranslation();

  // ****** Fetch Data ******
  const fetchDataStatus = async () => {
    try {
      if(id){
        const response = await GET(`${URL_DASHBOARD.STATUS_FOCUS_FACTORY}${id}/`)
        setStatus(response.data)
      }
    } catch (error) {
      message.error(error.errorMessage);
    }
  };
  const fetchDataPlantation = async () => {
    try {
      if(id){
        const response = await GET(`${URL_DASHBOARD.PLANTATION_FOCUS_FACTORY}${id}/`)
        setPlantation(response.data)
      }
    } catch (error) {
        message.error(error.errorMessage);
    }
  };

  const fetchDatafarmer = async () => {
    try {
      if(id){
        const response = await GET(`${URL_DASHBOARD.FARMER_FOCUS_FACTORY}${id}/`)
        setFarmer(response.data)
      }
    } catch (error) {
      message.error(error.errorMessage);
    }
  };

  const fetchData = async() => {
    console.log()
    await fetchDataStatus();
    await fetchDataPlantation();
    await fetchDatafarmer();
  }

  // ****** Setting Chart ******
  const styleCare = {
    boxShadow: '1px 1px 6px #DADADA',
    borderRadius: 0,
  };

  const styleTable= {
    boxShadow: '1px 1px 6px #DADADA',
    borderRadius: 0,
  }; 

  const styleCardList = {
    boxShadow: '1px 1px 6px #DADADA',
    borderRadius: 0,
  };

  const dataColor = (index ,total)=>{
    const startHue = 112
    const result = (startHue + (360*index/total))%360
    const color1 = (`hsl(${result}, 100%, 95%)`)
    return color1
  }

  const dataColorฺBorder = (index, total)=>{
    const startHue = 112
    const result = (startHue + (360*index/total))%360
    const color1 = (`hsl(${result}, 100%, 70%)`)
    return color1
  }

  const optionsBarFarmner = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: t("number_of_members_approvedpending_approval"),
        font: {
          size: 18, 
        },
      },
    },
    width: 'auto', // Set your desired width
    height: '200px', // Set your desired height
  };
    
  const dataBarFarmer = {
    labels: [t("number_of_members")],
    datasets: [
      {
        label: t("dashboard.wait_approval"),
        data: [status.status && status.status.waiting.farmer],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: [
        'rgb(54, 162, 235)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
      {
        label: t("dashboard.approved"),
        data: [status.status && status.status.approve.farmer],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgb(75, 192, 192)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
    ],
  };

  const optionsBarPlantation = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: t("number_of_plots_approvedpending_approval"),
        font: {
          size: 18, 
        },
      },
    },
    maintainAspectRatio: false, // Set to false to allow custom width and height
    width: 'auto', // Set your desired width
    height: '200px', // Set your desired height
  };
  
  const dataBarPlantation = {
    labels: [t("number_of_plots")],
    datasets: [
      {
        label:t("dashboard.wait_approval"),
        data: [status.status && status.status.waiting.plantation],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: [
        'rgb(54, 162, 235)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
      {
        label: t("dashboard.approved"),
        data: [status.status && status.status.approve.plantation],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgb(75, 192, 192)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
    ],
  };

  const optionsBarArea = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: t("the_number_of_acres_approvedpending_approval"),
        font: {
          size: 18, 
        },
      },
    },
    maintainAspectRatio: false, // Set to false to allow custom width and height
    width: 'auto', // Set your desired width
    height: '200px', // Set your desired height
  };

  const dataBarArea = {
    labels: [t("number_of_acres")],
    datasets: [
      {
        label:t("dashboard.wait_approval"),
        data: [status.status && status.status.waiting.area],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: [
        'rgb(54, 162, 235)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
      {
        label:  t("dashboard.approved"),
        data: [status.status && status.status.approve.area],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgb(75, 192, 192)',
        ],
        borderWidth: 1,
        barThickness: 25,
      },
    ],
  };

  const doughnutChartData = {
    labels: [`${FARMER_TYPE.OWNER} - ${farmer.type && farmer.type.owner} ${t("people")}`,
            `${FARMER_TYPE.RENTER} - ${farmer.type && farmer.type.renter} ${t("people")}`,
            `${FARMER_TYPE.PROXY} - ${farmer.type && farmer.type.proxy} ${t("people")}`],
    datasets: [
      {
        data:[
          farmer.type && farmer.type.owner,
          farmer.type && farmer.type.renter,
          farmer.type && farmer.type.proxy,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',//สีแดง//
          'rgba(255, 159, 64, 0.2)',//สีน้ำส้ม//
          'rgba(54, 162, 235, 0.2)',//สีน้ำน้ำเงิน//
        ],
        borderColor: [
          'rgb(255, 99, 132)',//สีแดง//
          'rgb(255, 159, 64)',//สีน้ำส้ม////
          'rgb(54, 162, 235)',//สีน้ำน้ำเงิน////
        ],
        borderWidth: 1
      }]
  };

  const doughnutChartDataFarmer = {
    labels: [`${FARMER_FOCUS.LOCAL} - ${farmer.area && farmer.area.local} ${t("people")}`,
            `${FARMER_FOCUS.FOREIGN} - ${farmer.area && farmer.area.foreign} ${t("people")}`], 
    datasets: [
      {
        // data:[farmer.type && farmer.type.owner, farmer.type.renter ,farmer.type.proxy],
        data:[
          farmer.area && farmer.area.local,
          farmer.area && farmer.area.foreign,
        ],
        // data: [farmer && farmer.map((item)=>item.type)],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
        ],
        borderWidth: 1
      }]
  };

  const doughnutChartOptions  = {
    maintainAspectRatio: false,
    width: '100px',
    height: '100px',
    plugins: {
      legend: {
        position: 'right',
        text: {
          size: 16, // Set your desired legend font size
        },
      },
      title: {
        display: true,
        text: t("dashboard.farmer_type"),
        font: {
          size: 18, // Set your desired title font size
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label ;
          }
        }
      }
    },
  };

  const doughnutChartOptionsFarmer  = {
    maintainAspectRatio: false,
    width: '100px',
    height: '100px',
    plugins: {
      legend: {
        position: 'right',
        text: {
          size: 16, // Set your desired legend font size
        },
      },
      title: {
        display: true,
        text: t("type_of_gardeners_in_area_outside_area"),
        font: {
          size: 18, // Set your desired title font size
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label ;
          }
        }
      }
    },
  };

  const pieChartDataRubber  = {
    labels: plantation.tree_types && plantation.tree_types.map((item)=>[`${item.name} - ${item.amount}%`]),
    datasets: [
      {
        data: plantation.tree_types && plantation.tree_types.map((item) => item.amount),
        backgroundColor: plantation.tree_types && plantation.tree_types.map((item, index) => dataColor(index, plantation.tree_types.length)),
        borderColor: plantation.tree_types && plantation.tree_types.map((item, index) => dataColorฺBorder(index, plantation.tree_types.length)),
        borderWidth: 1
      }]
  };

  const pieChartDataRubberAge = {
    labels: [`${AUBBER_AGE.LT_7}  ${plantation.age && plantation.age.lt_7}%`,
            `${AUBBER_AGE.L7_24}  ${plantation.age && _.get(plantation, 'age.7_24', null)}%`,
            `${AUBBER_AGE.GT_24}  ${plantation.age && plantation.age.gt_24} %`],
    datasets: [
      {
        data:[
          plantation.age && plantation.age.lt_7,
          plantation.age && _.get(plantation, 'age.7_24', null),
          plantation.age && plantation.age.gt_24,
        ],
        backgroundColor: [
          'rgba(255, 159, 64, 0.2)',//สีน้ำส้ม//
          'rgba(54, 162, 235, 0.2)',//สีน้ำน้ำเงิน//
          'rgba(255, 99, 132, 0.2)',//สีแดง//
        ],
        borderColor: [
          'rgb(255, 159, 64)',//สีน้ำส้ม//
          'rgb(54, 162, 235)',//สีน้ำน้ำเงิน//
          'rgb(255, 99, 132)',//สีแดง//
        ],
        borderWidth: 1
      }]
  };

  const pieChartOptionsDataRubber  = {
    maintainAspectRatio: false,
    width: '100px',
    height: '100px',
    plugins: {
      legend: {
        position: 'right',
        text: {
          size: 16, // Set your desired legend font size
        },
      },
      title: {
        display: true,
        text: t("all_rubber_varieties"),
        font: {
          size: 18, // Set your desired title font size
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label ;
          }
        }
      }
    },
  };

  const pieChartOptionsRubberAge = {
    maintainAspectRatio: false,
    width: '100px',
    height: '100px',
    plugins: {
      legend: {
        position: 'right',
        text: {
          size: 16, // Set your desired legend font size
        },
      },
      title: {
        display: true,
        text: t("all_rubber_ages"),
        font: {
          size: 18, // Set your desired title font size
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label ;
          }
        }
      }
    },
  };

  React.useEffect(() => {
    fetchData();
  },[])


  return (
    <>
    <Row>
      <Col  xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
        <StaticCard
          title={t("male")}
          showIcon={true}
          titleColor={'#7F7F7F'}
          icon={<ManOutlined style={{ fontSize: '60px', color: '#FFFFFF' }} />}
          titleFontSize={18}
          width={'auto'}
          height={170}
          style={{
            textAlign: 'center',
            background:
              'linear-gradient(90deg, rgba(123,211,234,1) 21%, rgba(199,242,254,1) 61%)',
          }}
          value={farmer.gender && farmer.gender.male}
          info={t("people")}
          valueColor={'#FFFFFF'}
          valueFontSize={40}
          />
      </Col>
      <Col  xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
        <StaticCard
          title={t("female")}
          showIcon={true}
          icon={<WomanOutlined style={{ fontSize: '60px', color: '#FFFFFF' }} />}
          titleColor={'#7F7F7F'}
          titleFontSize={18}
          width={'auto'}
          height={170}
          style={{
            textAlign: 'center',
            background:
            'linear-gradient(90deg, rgba(242,190,209,1) 21%, rgba(251,216,228,1) 61%)',
          }}
          value={farmer.gender && farmer.gender.female}
          info={t("people")}
          valueColor={'#FFFFFF'}
          valueFontSize={40}
        />
      </Col>
      <Col  xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
        <StaticCard
          textAlign={'center'}
          title={t("average_age_of_gardeners")}
          titleColor={'#7F7F7F'}
          titleFontSize={16}
          height={170}
          style={{textAlign:'center'}}
          width={'auto'}
          value={farmer.average_age && farmer.average_age}
          info={t("dashboard.year")}
          valueColor={'#58D68D'}
          valueFontSize={40}
          />
      </Col>
      <Col  xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
        <StaticCard
          textAlign={'center'}
          title={t("average_age_of_rubber_trees")}
          titleColor={'#7F7F7F'}
          titleFontSize={16}
          height={170}
          style={{textAlign:'center'}}
          width={'auto'}
          value={plantation.average_age && plantation.average_age}
          info={t("dashboard.year")}
          valueColor={'#58D68D'}
          valueFontSize={40}
          />
      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop : 16}}>
      <Col  xxl={8} xl={8} lg={24} md={24} sm={24} xs={24}>
        <Card style={styleCare}>
          <Bar options={optionsBarFarmner} data={dataBarFarmer} height={250} />
        </Card>
      </Col>
      <Col  xxl={8} xl={8} lg={24} md={24} sm={24} xs={24}>
        <Card style={styleCare}>
          <Bar options={optionsBarPlantation} data={dataBarPlantation} height={250} />
        </Card>
      </Col>
      <Col  xxl={8} xl={8} lg={24} md={24} sm={24} xs={24}>
      <Card style={styleCare}>
          <Bar options={optionsBarArea} data={dataBarArea} height={250}/> 
      </Card>
    </Col>
    </Row>
    <Row style={{marginTop:"16px"}}>
      <Col  xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card style={styleCare}>
          <Doughnut data={doughnutChartData} options={doughnutChartOptions} width={'300'} height={250}/>
        </Card>
      </Col>
      <Col  xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card style={styleCare}>
          <Doughnut data={doughnutChartDataFarmer} options={doughnutChartOptionsFarmer} width={'300'} height={250}/>
        </Card>
      </Col>
    </Row>
    <Row style={{marginTop:"16px"}}>
      <Col md={24} sm={24} xs={24}>
        <Row >
          <Col  xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Card style={styleCare} >
              <Pie data={pieChartDataRubber} options={pieChartOptionsDataRubber} width={'300'} height={'250'}/>
            </Card>
          </Col>
          <Col  xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Card style={styleCare}>
              <Pie data={pieChartDataRubberAge} options={pieChartOptionsRubberAge} width={'300'} height={'250'}/>
          </Card>
          </Col>
        </Row>
      </Col>
    </Row>  
    </>
  )
}