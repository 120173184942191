import { Card, Col, Row, Table, message, Select, Spin, DatePicker, Form } from "antd";
import React from "react";
import { GET } from "../../frameworks/HttpClient";
import { URL_DASHBOARD, URL_MEMBER } from "../../constants/urls";
import _ from "lodash";
import { formatComma } from "../../frameworks/Util";
import dayjs from "dayjs";
import { MONTH_FORMAT, YEAR_FORMAT } from "../../constants/string";
import { useTranslation } from 'react-i18next';

export default function DashboardView() {
  const [fetching, setFetching] = React.useState(false);
  const { t, i18n } = useTranslation();
  // Options
  const [factoryOptions, setFactoryOptions] = React.useState([]);
  const [ccOptions, setCCOptions] = React.useState([]);
  const [ccOptionsRemain, setCCOptionsRemain] = React.useState([]);
  const [debouncedCode, setDebouncedCode] = React.useState(null);
  const [debouncedCodeRemain, setDebouncedCodeRemain] = React.useState(null);
  // Stock
  const [selectedCCStock, setSelectedCCStock] = React.useState("");
  const [stockYear, setStockYear] = React.useState(dayjs())
  const [dataStock, setDataStock] = React.useState(null);
  // Stock (Remaining)
  const [selectedCCStockRemain, setSelectedCCStockRemain] = React.useState("");
  const [dataStockRemain, setDataStockRemain] = React.useState(null);
  // Delivery - year
  const [delYear, setDELYear] = React.useState(dayjs());
  const [dataDelivery, setDataDelivery] = React.useState(null);
  // Delivery - month
  const [delMonth, setDELMonth] = React.useState(dayjs());
  const [selectedCCDel, setSelectedCCDel] = React.useState("");
  const [dataDeliveryMonth, setDataDeliveryMonth] = React.useState(null);
  // Delivery - factory month
  const [factoryDelMonth, setFactoryDelMonth] = React.useState(dayjs())
  const [targetFactoryDel, setTargetFactoryDel] = React.useState("");
  const [dataFactoryDel, setDataFatoryDel] = React.useState([]);

  // Fetch Stock
  const fetchDataStock = async () => {
    const cc = (selectedCCStock && selectedCCStock.value) ? selectedCCStock.value : ""
    let params = {}
    if (stockYear)
      params["year"] = stockYear ? stockYear.format(YEAR_FORMAT) : "";

    try {
      const response = await GET(`${URL_DASHBOARD.STOCK_FOCUS}${cc}`, params)
      setDataStock(response.data)
    } catch (error) {
      message.error(error.errorMessage);
    }
  }

    // Fetch Stock (Remaining)
    const fetchDataStockRemain = async () => {
      const cc = (selectedCCStockRemain && selectedCCStockRemain.value) ? selectedCCStockRemain.value : ""
      let params = { station : cc}
      try {
        const response = await GET(`${URL_DASHBOARD.STOCK_CURRENT_DASHBOARD}`, params)
        setDataStockRemain(response.data)
      } catch (error) {
        message.error(error.errorMessage);
      }
    }

  // Fetch DEL
  const fetchDataDelivery = async () => {
    let params = {}
    if (delYear)
      params["year"] = delYear ? delYear.format(YEAR_FORMAT) : "";

    try {
      const response = await GET(`${URL_DASHBOARD.DELIVERY_FOCUS}`, params)
      setDataDelivery(response.data)
    } catch (error) { 
      message.error(error.errorMessage);
    }
  }

  // Fetch DEL per month
  const fetchDataDeliveryMonth = async () => {
    const cc = (selectedCCDel && selectedCCDel.value) ? selectedCCDel.value : ""
    let params = {}
    if (delMonth)
      params["month"] = delMonth ? delMonth.format(MONTH_FORMAT) : "";

    try {
      const response = await GET(`${URL_DASHBOARD.DELIVERY_FOCUS}${cc}`, params)
      setDataDeliveryMonth({...response.data})
    } catch (error) { 
      message.error(error.errorMessage);
    }
  } 

  // Fetch Del of factory
  const fetchDelFactory = async () => {
    const factory = (targetFactoryDel && targetFactoryDel.value) ? targetFactoryDel.value : ""
    let params = {}
    if (factoryDelMonth)
      params["month"] = factoryDelMonth ? factoryDelMonth.format(MONTH_FORMAT) : "";

    try {
      const response = await GET(`${URL_DASHBOARD.DELIVERY_FACTORY_DASHBOARD}${factory}`, params);
      setDataFatoryDel(response.data);
    } catch (error) { 
      message.error(error.errorMessage);
    }
  } 

  // Fetch CC List
  const fetchCollectingCenter = async (type) => {
    setFetching(true);
    const searchParams = {
      search: debouncedCode ? debouncedCode : "",
      page_size: 500,
    }
    try {
      const response = await GET(URL_MEMBER.COLLECTING_CENTER, searchParams);
      const ccList = response.data.results.map((item) => {
        return {
          ...item,
          key: item.id,
          label: `${item.name}, ${item.sub_district_detail}`,
          value: item.id,
        }
      })
      const ccListRemain = response.data.results.map((item) => {
        return {
          ...item,
          key: item.user,
          label: `${item.name}, ${item.sub_district_detail}`,
          value: item.user,
        }
      })
      if(type === 'year'){
        setCCOptions([
          {key: "all", value: "", label: t("all")},
          ...ccList
        ])
      } else if(type === 'remain'){
        setCCOptionsRemain([
          {key: "all", value: "", label: t("all")},
          ...ccListRemain
        ])
      } else {
        setCCOptions([
          {key: "all", value: "", label: t("all")},
          ...ccList
        ])
        setCCOptionsRemain([
          {key: "all", value: "", label: t("all")},
          ...ccListRemain
        ])
      }
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  // Fetch Factory list
  const fetchFactories = async () => {
    try {
      const response = await GET(URL_MEMBER.FACTORY, {page_size: 1000});
      const factories = response.data.results.map((item) => {
        return {
          ...item,
          key: item.id,
          value: item.id,
          label: item.name
        }
      })
      setFactoryOptions([
        {key: "all", value: "", label: t("all")},
        ...factories
      ])
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  const columns = [
    {
      title: t("dashboard.product_name"),
      dataIndex: 'product_name',
      width: 150,
    },
    {
      title: t("dashboard.gross_weight"),
      dataIndex: 'gross_weight',
      width: 120,
      render: (item) => { return formatComma(item)}
    },
    {
      title: t("dashboard.dry_weight"),
      dataIndex: 'net_weight',
      width: 120,
      render: (item) => { return formatComma(item)}
    },
    {
      title: t("dashboard.drc"),
      dataIndex: 'avg_drc',
      width: 120,
      render: (item) => { return formatComma(item)}
    },
  ];

  const factoryColumns = [
    {
      title: t("dashboard.factory"),
      dataIndex: 'factory_name',
      width: 140,
    },
    {
      title: t("dashboard.product_name"),
      dataIndex: 'product_name',
      width: 140,
    },
    {
      title: t("dashboard.gross_weight"),
      dataIndex: 'gross_weight',
      render: (item) => { return formatComma(item)}
    },
    {
      title: t("dashboard.dry_weight"),
      dataIndex: 'net_weight',
      render: (item) => { return formatComma(item)}
    },
    {
      title: t("dashboard.drc"),
      dataIndex: 'avg_drc',
      render: (item) => { return formatComma(item)}
    },
  ]

  const debounceCC = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800),
    []
  );


  const debounceCCRemain = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCodeRemain(_searchVal) }, 800),
    []
  );

  React.useEffect(() => {
    if(delMonth || selectedCCDel)
      fetchDataDeliveryMonth();
  },[delMonth, selectedCCDel])
    
  React.useEffect(() => {
    fetchFactories();
    fetchCollectingCenter();
    fetchDataStockRemain()
  },[])

  React.useEffect(() => {
    if (debouncedCode)
    fetchCollectingCenter('year');
  }, [debouncedCode])

  React.useEffect(() => {
    if (debouncedCodeRemain)
    fetchCollectingCenter('remain');
  }, [debouncedCodeRemain])

  React.useEffect(() => {
    if (delYear) {
      fetchDataDelivery();
    }
  }, [delYear])

  React.useEffect(() => {
    if(selectedCCStock || stockYear){
      fetchDataStock()
    }
  },[selectedCCStock, stockYear])
  
  React.useEffect(() => {
    if(selectedCCStockRemain){
      fetchDataStockRemain()
    }
  },[selectedCCStockRemain])

  React.useEffect(() => {
    if (targetFactoryDel || factoryDelMonth){
      fetchDelFactory();
    }
  }, [targetFactoryDel, factoryDelMonth])

  return(
    <Row gutter={16}>
      {/* Stock for CC per year */}
      <Col xxl={8} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card style={{ marginBottom : 10, }} title={"STOCK"}>
          <Form layout="horizontal">
            <Row gutter={16}>
              <Col md={16} sm={10} xs={24}>
                <Form.Item label={t("dashboard.collecting_center")}>
                  <Select
                    initialvalues={"-"}
                    autoComplete="off" 
                    onChange={(value => setSelectedCCStock(value))}
                    showSearch
                    labelInValue
                    allowClear = {true}
                    filterOption={false}
                    notFoundContent={fetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceCC}
                    options={ccOptions}
                    defaultValue=''
                    value={selectedCCStock}
                    style={{ width : '100%', marginBottom : 10 }}/>
                </Form.Item>
              </Col>
              
              <Col lg={8} sm={8} xs={24}>
                <Form.Item label={t("dashboard.year")}>
                  <DatePicker 
                    picker={"year"} 
                    style={{ width: '100%', marginBottom : 10 }} 
                    onChange={(date, dateStr) => setStockYear(date)}
                    value={stockYear}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Table
            columns={columns}
            dataSource={dataStock}
            pagination={false}/>
        </Card>
      </Col>

      <Col xxl={8} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card style={{ marginBottom : 10, }} title={t("stock_remaining")}>
          <Form layout="horizontal">
            <Row gutter={16}>
              <Col md={16} sm={10} xs={24}>
                <Form.Item label={t("dashboard.collecting_center")}>
                  <Select
                    initialvalues={"-"}
                    autoComplete="off" 
                    onChange={(value => setSelectedCCStockRemain(value))}
                    showSearch
                    labelInValue
                    allowClear = {true}
                    filterOption={false}
                    notFoundContent={fetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceCCRemain}
                    options={ccOptionsRemain}
                    defaultValue=''
                    value={selectedCCStockRemain}
                    style={{ width : '100%', marginBottom : 10 }}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Table
            columns={columns}
            dataSource={dataStockRemain}
            pagination={false}/>
        </Card>
      </Col>

      {/* DEL Transaction per year */}
      <Col xxl={8} xl={12} lg={24} sm={24} xs={24}>
        <Card style={{ marginBottom : 10, }} title={t("dashboard.delivery_year")} >
          <Form layout="horizontal">
            <Form.Item label={t("dashboard.year")}>
              <DatePicker 
                picker={"year"} 
                style={{ width: '100%', marginBottom : 10 }} 
                onChange={(date, dateStr) => setDELYear(date)}
                value={delYear}/>
            </Form.Item>
          </Form>
        
          <Table
            columns={columns}
            dataSource={dataDelivery && dataDelivery.products}
            pagination={false}/>
        </Card>
      </Col>

      {/* DEL Transaction per CC per month */}
      <Col xxl={8} xl={12} lg={24} sm={24} xs={24}>
        <Card style={{ marginBottom : 10, }} title={t("dashboard.delivery_month")} >
          <Form layout="horizontal">
            <Row gutter={16}>
              <Col md={16} sm={16} xs={24}>
                <Form.Item label={t("dashboard.collecting_center")}>
                  <Select 
                    initialvalues={"-"}
                    autoComplete="off" 
                    showSearch
                    labelInValue
                    filterOption={false}
                    notFoundContent={fetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceCC}
                    onChange={(value) => setSelectedCCDel(value)}
                    options={ccOptions}
                    allowClear={"-"}  
                    style={{ width : '100%', marginBottom : 10 }}
                    value={selectedCCDel}/>
                </Form.Item>
              </Col>
              <Col lg={8} sm={8} xs={24}>
                <Form.Item label={t("month")}>
                  <DatePicker 
                    picker={"month"} 
                    style={{ width: '100%', marginBottom : 10 }} 
                    onChange={(date) => setDELMonth(date)}
                    value={delMonth}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Table
            columns={columns}
            dataSource={dataDeliveryMonth && dataDeliveryMonth.products}
            pagination={false}/>
        </Card>
      </Col>

      {/* DEL for factory */}
      <Col xxl={8} xl={12} lg={24} sm={24} xs={24}>
        <Card style={{ marginBottom : 10, }} title={t("dashboard.delivery_to_factory")} >
          <Form layout="horizontal">
            <Row gutter={16}>
              <Col md={16} sm={16} xs={24}>
                <Form.Item label={t("dashboard.factory")}>
                  <Select 
                    initialvalues={"-"}
                    autoComplete="off" 
                    showSearch
                    labelInValue
                    filterOption={false}
                    notFoundContent={fetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceCC}
                    onChange={(value) => setTargetFactoryDel(value)}
                    options={factoryOptions}
                    allowClear={"-"}  
                    style={{ width : '100%', marginBottom : 10 }}
                    value={targetFactoryDel}/>
                </Form.Item>
              </Col>
              <Col lg={8} sm={8} xs={24}>
                <Form.Item label={t("month")}>
                  <DatePicker 
                    picker={"month"} 
                    style={{ width: '100%', marginBottom : 10 }} 
                    onChange={(date) => setFactoryDelMonth(date)}
                    value={factoryDelMonth}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Table
            columns={factoryColumns}
            dataSource={dataFactoryDel ? dataFactoryDel : []}
            pagination={false}/>
        </Card>
      </Col>
    </Row>
  )
}