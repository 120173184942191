import React from 'react'
import { Row, Col, Image, Layout, Button, theme, Form, Input, DatePicker, Select, Spin, message, Alert, Modal } from 'antd'
import { EditOutlined, UploadOutlined, QrcodeOutlined, ImportOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout'
import userImg from '../assets/user.png'
import HeaderScreen from '../components/common/HeaderScreen'
import PDPAModal from '../components/farmer/PDPAModal';
import ConsentModal from '../components/farmer/ConsentModal';
import ImageEditorModal from '../components/common/ImageEditorModal';
import ImgCrop from 'antd-img-crop';
import { DATE_FORMAT } from '../constants/string';
import { GET, POST, PATCH } from '../frameworks/HttpClient';
import { URL_COMMON, URL_MEMBER } from '../constants/urls';
import { useWindowDimensionContext } from '../hooks/WindowDimensionProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from '../routes/CustomRoutes';
import dayjs from 'dayjs';
import _ from "lodash";
import { BG_LAYOUT_COLOR, CYAN_COLOR, DARK_GRAY_COLOR } from '../constants/color';
import QRCodeModal from '../components/farmer/QRCodeModal';
import AddJsonModal from '../components/common/AddJsonModal';
import { IMPORT_JSON_TYPE } from '../constants/string';
import { useTranslation } from 'react-i18next';

export default function MemberRegisterScreen() {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const citizen_id = Form.useWatch('citizen_id', form)
  const [farmer, setFarmer] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  // Handle subsiatrict
  const [subdistricts, setSubdistricts] = React.useState([]);
  const [debouncedCode, setDebouncedCode] = React.useState(null);
  const [fetching, setFetching] = React.useState(false); 
  // Handle signature
  const [signaturePDPA, setSignaturePDPA] = React.useState(null);
  const [signatureFSC, setSignatureFSC] = React.useState(null);
  const [isFSC, setIsFSC] = React.useState(false);
  // Handle Modal
  const [openPDPAModal, setOpenPDPAModal] = React.useState(false);
  const [openConsentModal, setOpenConsentModal] = React.useState(false);
  const [openQR, setOpenQR] = React.useState(false);
  const [openImportJson, setOpenImportJson] = React.useState(false);
  // Handle image 
  const [uploadFile, setUploadFile] = React.useState(null)
  const [imageData, setImageData] = React.useState(null)
  const uploadRef = React.useRef(null);
  const { t, i18n } = useTranslation();

  const {
		token: { colorBgContainer, colorWarning, colorInfo, colorPrimary, colorError },
	} = theme.useToken();

  const debounceSubdistrict = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800),
    []
  );

  // Search address
	const fetchSubdistrict = async () => {
		setFetching(true);
		try {
			const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${debouncedCode}`)
			setSubdistricts(response.data.results.map((item) => {
				return {
					...item,
					label: item.display_name,
					value: item.subdistrict_id
				}
			}))
		} catch (error) {
			console.log(error.errorMessages)
		} finally {
			setFetching(false);
		}
	}

  // Fetch farmer detail
  const fetchFarmer = async (id) => {
    if (!id) {
      setErrorMessages("ไม่พบข้อมูลสมาชิก!")
      return;
    }

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_MEMBER.FARMER}${id}/`)
      setFarmer(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  // ดึงข้อมูลจากบัตรประชาชน
  const fetchInfoFromIDCard = async (imageData) => {
    if (!imageData) {
      setErrorMessages("ID Card not found!")
      return
    }

    setErrorMessages(null);
    try {
      // รอเปลี่ยน API
      const response = POST(URL_COMMON.PERSONAL_CARD_EXTRACT, {card: imageData})
      console.log(response)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const genderTH = [
    { value: 'นาย', label: t("prefix.mr") },
    { value: 'นาง', label: t("prefix.mrs") },
    { value: 'นางสาว', label: t("prefix.ms") },
    { value: 'เด็กชาย', label: t("prefix.boy") },
    { value: 'เด็กหญิง', label: t("prefix.girl") },
  ]

  const genderEN = [
    { value: 'นาย', label: t("prefix.mr") },
    { value: 'นาง', label: t("prefix.mrs") },
    { value: 'นางสาว', label: t("prefix.ms") },
  ]

  // Update or Create farmer
  const handleFarmer = async () => {
    setErrorMessages(null);
		setLoading(true);
    try{
      // Prepare data
      let data = await form.validateFields();
      if (data["plantation_amount"] !== null)
        delete data["plantation_amount"]
      if (data["birthdate"])
        data["birthdate"] = data.birthdate.format(DATE_FORMAT);
      if (data.sub_district) 
        data['sub_district'] = data.sub_district.value;
      if (signaturePDPA)
        data['pdpa_signature'] = signaturePDPA.signature;
      if (signatureFSC)
        data['consent_signature'] = signatureFSC.signature;
      if(imageData)
        data['card'] = imageData
            
      data['is_fsc'] = isFSC;
      
      // Update or Create farmer
      if (farmer && farmer.id) {
        // Update
        const response = await PATCH(`${URL_MEMBER.FARMER}${farmer.id}/`, data);
        setFarmer(response.data);
        message.success("Update profile successfully");
      } else {
        // Create
        await POST(URL_MEMBER.FARMER, data);
        navigate(PATH.MEMBERS)
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  // Update Signature (PDPA, Consent)
  const updateSignature = async (data) => {
    if (!data) {
      setErrorMessages("ไม่พบลายเซ็นต์!")
      return;
    }

    if (!farmer) {
      setErrorMessages("ไม่พบสมาชิก!")
      return;
    }

    setErrorMessages(null);
    setLoading(true);
    try {
      const response = await PATCH(`${URL_MEMBER.FARMER}${farmer.id}/`, data);
      setFarmer(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }

  }

  const validateCitizenId = async () => {
    try {
      await GET(`${URL_MEMBER.CHECK_DUPLICATE_CITIZEN_ID}${citizen_id}/`, {id: farmer && farmer.id})
    } catch(error) {
      if(error.status === 409) {
        Modal.error({
          title: "หมายเลขบัตรประชาชนซ้ำ (Duplicated)",
          content: `หมายเลขบัตรประชาชน ${citizen_id} ถูกลงทะเบียนในระบบแล้ว`
        })
      }
    }
  }

  React.useEffect(() => {
		if (debouncedCode)
			fetchSubdistrict();
	}, [debouncedCode])

  React.useEffect(() => {
		form.resetFields();
    if (params.id) {
      fetchFarmer(params.id);
    } else {
      setOpenPDPAModal(true);
    }
	}, [])

  React.useEffect(() => {
    if (farmer) {
      const targetSubDistrict = farmer.sub_district ? i18n.language === 'th' ? {value: farmer.sub_district, label: farmer.sub_district_detail}
        : {value: farmer.sub_district, label: farmer.sub_district_detail_en}
        : null
      
      form.setFieldsValue({
        ...farmer,
        birthdate: farmer.birthdate ? dayjs(farmer.birthdate, DATE_FORMAT) : null,
        sub_district: targetSubDistrict, 
      })
      setIsFSC(farmer.is_fsc);
    }
  }, [farmer, i18n.language])

    return (
      <Layout style={{ minHeight: "100vh", background: BG_LAYOUT_COLOR }}>
        <HeaderScreen title={farmer ? `${t("member")} [${farmer.full_name}]` : t("register.register_member")} />
        
      <Content 
        style={{ 
          margin: "0px 24px 24px 24px", 
          padding: 24,
          textAlign: "left", 
          minHeight: 280,
          background: colorBgContainer }}>
        {errorMessages && 
          <Alert
            style={{marginBottom: 16, textAlign: "left"}}
            message={errorMessages}
            type="error"
            showIcon/>
        }

        <div style={{textAlign: "right", marginBottom: 16}}>
          {farmer && !farmer.is_fsc && (
            <Button 
              type={"primary"}
              style={{marginLeft: 8, background: colorInfo}}
              onClick={() => {
                setOpenConsentModal(true);
                setIsFSC(true);
              }}>
                อัพเกรด
            </Button>
          )}

          {farmer && !farmer.pdpa_signature && (
            <Button 
              type={"dashed"}
              style={{marginLeft: 8, borderColor: colorError}}
              onClick={() => {
                setOpenPDPAModal(true);
              }}>{t("register.sign_pdpa")}</Button>
          )}
          
          {farmer && farmer.is_fsc && !farmer.consent_signature && (
            <Button 
              type={"dashed"}
              style={{marginLeft: 8, borderColor: colorPrimary}}
              onClick={() => {
                setOpenConsentModal(true);
              }}>
                {t("register.sign_fsc")}
            </Button>
          )}

          {!isOnMobile && 
              <Button
                type="primary"
                style={{ marginLeft: 8, borderColor: DARK_GRAY_COLOR, background: DARK_GRAY_COLOR }}
                onClick={() => setOpenImportJson(true)}
                icon={<ImportOutlined />}>
                Import JSON
              </Button>
          }

          {farmer && (
            <Button 
              type={"primary"}
              style={{marginLeft: 8, borderColor: CYAN_COLOR, background: CYAN_COLOR}}
              icon={<QrcodeOutlined />}
              onClick={() => setOpenQR(true)}>
                QR Code
            </Button>
          )}
        </div>

        <Form
          form={form}
          layout="vertical"
          autoComplete="off">
          <Row gutter={16}>
            <Col md={6} sm={24} xs={24}>
              <Row gutter={16}>
                <Col md={24} sm={24} xs={24} style={{marginBottom: 6}}>
                  <div style={{ textAlign: "center" }} className='section-wrapper'>
                    <Image
                      src={imageData ? imageData : (farmer && farmer.card) ? farmer.card : userImg}
                      height={180} 
                      width={"auto"} />
                    
                    <div>
                      <Button 
                        style={{marginTop: 8}}
                        icon={<UploadOutlined />} 
                        onClick={() => uploadRef.current.click()}>
                          {t('farmers.upload_picture')}
                      </Button>
                    </div>
                  </div>
                </Col>

                <Col md={24} sm={24} xs={24}>
                  <Form.Item  
                    name={"citizen_id"}
                    label={t('farmers.citizen_id')}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" maxlength={13} onBlur={validateCitizenId}/>
                  </Form.Item>
                </Col>

                <Col md={24} sm={24} xs={24}>
                  <Form.Item  
                    name={"raot_number"}
                    label= {t("farmers.raot_number")}
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col md={18} sm={24} xs={24} >
              <Row gutter={16}>
                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"title"}
                    label={t("farmers.title")}
                    rules={[{ required: true }]}>
                      <Select
                        style={{ width: '100%' }}
                        options={i18n.language === "th" ? genderTH : genderEN}
                        onChange={value => {
                          if(value === "นาย" || value === "เด็กชาย") {
                            form.setFieldValue('title_en', "Mr.")
                          }
                          else if(value === "นาง") {
                            form.setFieldValue('title_en', "Mrs.")
                          }
                          else if (value === "นางสาว" || value === "เด็กหญิง") {
                            form.setFieldValue('title_en', "Ms.")
                          }
                        }}
                      />
                  </Form.Item>
                </Col>

                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name={"first_name"}
                    label={t("farmers.first_name")}
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name={"last_name"}
                    label={t("farmers.last_name")}
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"title_en"}
                    label="Title"
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" readOnly={true}/>
                  </Form.Item>
                </Col>

                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name={"first_name_en"}
                    label={t("farmers.first_name_en")}
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name={"last_name_en"}
                    label={t("farmers.last_name_en")}
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={5} sm={24} xs={24}>
                  <Form.Item  
                    name={"birthdate"}
                    label={t("farmers.birthday")}
                    rules={[{ required: true }]}>
                      <DatePicker style={{ width: '100%' }} format={DATE_FORMAT}/>
                  </Form.Item>
                </Col>

                <Col md={5} sm={24} xs={24}>
                  <Form.Item  
                    name={"gender"}
                    label={t("farmers.gender")}
                    rules={[{ required: true }]}>
                      <Select 
                        style={{ width: '100%' }}
                        options={[
                          { value: 'm', label: t("gender.m") },
                          { value: 'f', label: t("gender.f") },
                          { value: 'unknown', label: t("gender.unknown") }
                        ]}
                      />
                  </Form.Item>
                </Col>

                <Col md={5} sm={24} xs={24}>
                  <Form.Item  
                    name={"phone"}
                    label={t("farmers.phone")}
                    rules={[{ required: true }]}>
                      <Input 
                        autoComplete="off" 
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}/>
                  </Form.Item>
                </Col>

                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name={"email"}
                    label={t("farmers.email")}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={24} sm={24} xs={24}>
                  <Form.Item 
                    name={"address"}
                    label={t("farmers.address")}
                    rules={[{ required: true }]}>
                    <Input.TextArea />
                  </Form.Item>
                </Col>

                <Col md={14} sm={24} xs={24}>
                  <Form.Item  
                    name={"sub_district"}
                    label={t("farmers.subdistrict_district_province_zip_code")}
                    rules={[{ required: true }]}>
                  <Select 
                      initialvalues={"-"}
                      autoComplete="off" 
                      showSearch
                      labelInValue
                      filterOption={false}
                      notFoundContent={fetching ? <Spin size="small" /> : "-"}
                      onSearch={debounceSubdistrict}
                      options={subdistricts}
                      allowClear={"-"}/>
                  </Form.Item>
                </Col>

                <Col md={10} sm={24} xs={24}>
                  <Form.Item  
                    name={"plantation_amount"}
                    label={t("register.number_of_plots")}>
                      <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <ImageEditorModal
          open={uploadFile ? true : false}
          image={uploadFile}
          onUpdated={(image) => {
            setImageData(image)
            setUploadFile(null)
          }}
          onClose={() => setUploadFile(null)}/>

        <ImgCrop aspect={16 / 9} aspectSlider>
          <input id="imgSelect"
            type="file"
            accept="image/*"
            ref={uploadRef}
            style={{ display: 'none' }}
            onChange={async (evt) => {
              if (evt.target.files.length > 0) {
                setUploadFile(URL.createObjectURL(evt.target.files[0]))
                evt.target.value = null  // clear reference to enable select same file
              }
            }}/>
        </ImgCrop>

        <div style={{ textAlign: "center", marginTop: 16}}>
          <Button 
            loading={loading} 
            style={{ background: farmer ? colorWarning : null }}
            type={"primary"} 
            shape={"round"} 
            icon={farmer ? <EditOutlined /> : null}
            onClick={handleFarmer}>{farmer ? t("edit") : t("register.register")}
          </Button>
        </div>
      </Content>

      <PDPAModal 
        open={openPDPAModal}
        member={farmer}
        onClose={() => setOpenPDPAModal(false)}
				onUpdated={(sign, isFSC) => {
          setOpenPDPAModal(false);
          setIsFSC(isFSC)
          if (!farmer) {
            setSignaturePDPA(sign)
            if (isFSC) {
              setOpenConsentModal(true);
            }
          } else {
            updateSignature({pdpa_signature: sign.signature})
          }
        }}/>
      
      <ConsentModal 
        open={openConsentModal}
        onClose={() => setOpenConsentModal(false)}
        onUpdated={(sign) => {
          setOpenConsentModal(false);
          if (!farmer) {
            setSignatureFSC(sign);
          } else {
            setIsFSC(true);
            updateSignature({
              is_fsc: true,
              consent_signature: sign.signature
            })
          }
        }}/>

        <QRCodeModal
          open={openQR}
          memberId={farmer ? farmer.id : null}
          memberName={farmer ? i18n.language === 'th' ? farmer.full_name : farmer.full_name_en : null}
          onClose={() => setOpenQR(false)} />

        <AddJsonModal 
          open={openImportJson}
          onClose={() => setOpenImportJson(false)}
          importType={IMPORT_JSON_TYPE.FARMER}
          onImported={(data) => {
            form.setFieldsValue(data)
          }}
        />
    </Layout>
  )
}