import React from "react";
import { Button, Col, Modal, Row, theme } from "antd";
import { URL_REPORT } from "../../constants/urls";
import ReportModal from "../officer/ReportModal"
import Header from "../common/Header";
import Lottie from "lottie-react";
import lottieReport from "../../assets/lottie_report.json"
import { REPORT_FILTER } from "../../constants/string";
import { FileExcelFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function ReportsViews() {
  const [targetReport, setTargetReport] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const { t, i18n } = useTranslation();

  const Reports = [
    {
      id: 1, 
      label: `1. ${t("reports.rubber5")}`, 
      detail: t("reports.rubber5"),
      filter: [REPORT_FILTER.MONTH],
      required: true,
      url: URL_REPORT.RUBBER05
    },
    {
      id: 2, 
      label: `2. ${t("reports.rubber6")}`, 
      detail: t("reports.rubber6"),
      filter: [REPORT_FILTER.MONTH],
      required: true,
      url: URL_REPORT.RUBBER06
    },
    {
      id: 3, 
      label: `3. ${t("reports.rubber7")}`, 
      detail: t("reports.rubber7"),
      filter: [REPORT_FILTER.MONTH],
      required: true,
      url: URL_REPORT.RUBBER07
    },
    {
      id: 4, 
      label: `4. ${t("reports.rubber8")}`, 
      detail: t("reports.rubber8"),
      filter: [REPORT_FILTER.MONTH, REPORT_FILTER.PRODUCT_TYPE],
      required: true,
      url: URL_REPORT.RUBBER08
    },
    {
      id: 5, 
      label: `5. ${t("reports.delivery_by_product")}`, 
      detail: t("reports.delivery_by_product"),
      filter: [],
      // required: true,
      url: URL_REPORT.DELIVERY_BY_PRODUCT
    },
    {
      id: 6, 
      label: `6. ${t("reports.delivery_by_month")}`,
      detail: t("reports.delivery_by_month"),
      filter: [],
      // required: true,
      url: URL_REPORT.DELIVERY_BY_MONTH
    },
    {
      id: 7, 
      label: `7. ${t("reports.report_of_plantation")}`, 
      detail: t("reports.report_of_plantation"),
      filter: [REPORT_FILTER.DEL001],
      required: true,
      url: URL_REPORT.REPORT_OF_PLANTATION
    },
    {
      id: 8,
      label: `8. ${t("reports.report_of_plantation_goejson")}`,
      detail: t("reports.report_of_plantation_goejson"),
      filter: [REPORT_FILTER.DEL001],
      required: true,
      hideExcel: true,
      geojson: true,
      url: URL_REPORT.REPORT_OF_PLANTATION_GEOJSON
    },
    {
      id: 9, 
      label: `9. ${t("reports.gm")}`, 
      detail: t("reports.gm"),
      filter: [REPORT_FILTER.CCT, REPORT_FILTER.APPROVE_DATE_RANGE, REPORT_FILTER.STATUS, REPORT_FILTER.PROVINCE],
      required: true,
      url: URL_REPORT.GM_REPORT
    },
    {
      id: 10,
      label: `10. ${t("reports.plantation_overlap")}`, 
      detail: t("reports.plantation_overlap"),
      filter: [],
      url: URL_REPORT.PLANTATION_OVERLAP  
    },
    {
      id: 11,
      label: `11. ${t("reports.layer_overlap")}`, 
      detail: t("reports.layer_overlap"),
      filter: [],
      url: URL_REPORT.LAYER_OVERLAP 
    }
    // {
    //   id: 9, 
    //   label: `9. ${t("reports.m01")}`, 
    //   detail: t("reports.m01"),
    //   filter: [REPORT_FILTER.MONTH_RANGE],
    //   required: true,
    //   url: null
    // },
    // {
    //   id: 10, 
    //   label: `10. ${t("reports.del01")}`, 
    //   detail: t("reports.del01"),
    //   filter: [],
    //   required: true,
    //   url: null
    // },
    // {
    //   id: 11, 
    //   label: `11. ${t("reports.delivery_by_factory")}`, 
    //   detail: t("reports.delivery_by_factory"),
    //   filter: [],
    //   required: true,
    //   url: null
    // },
  ]

  const {
		token: {colorInfoText}
	} = theme.useToken();

  return (
    <div>
      {targetReport && loading != null &&
				<Modal
					title={
					<>
						Generating - {targetReport.label} Excel <FileExcelFilled />
					</>}
					open={loading != null}
					style={{
						top: 20,
					}}
					closable={false}
					footer={[]}
				>
					<Lottie animationData={lottieReport} />
				</Modal>
			}

      <Header title={"Report"} />
      <Row 
        gutter={[16, 16]}
        style={{marginTop: 16, marginBottom: 16}}>
        {Reports.map(item => (
          <Col key={item.id} xl={8} sm={12} xs={24}>
            <Button
              style={{width: "100%", textAlign: "left", borderColor: colorInfoText}}
              disabled={item.url ? false : true}
              onClick={() => setTargetReport(item)}>
                {item.label}
            </Button>
          </Col>
        ))}
      </Row>

      {/* Modal */}
			<ReportModal
				namePrefix={targetReport ? targetReport.label : null}
				open={targetReport ? true : false}
				data={targetReport}
				onStartLoading={() => setLoading(true)}
				onEndLoading={() => setLoading(null)}
				onClose={() => setTargetReport(null)}/>
    </div> 
  )
}
