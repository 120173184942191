import React from 'react'
import propTypes from "prop-types";
import { Alert, Button, Checkbox, Modal, Row, Image } from "antd";
import { useNavigate } from "react-router-dom";
import PDPAModalENView from './PDPAModalENView';
import PDPAModalTHView from './PDPAModalTHView';
import Header from '../common/Header';
import SignaturePad from 'react-signature-canvas';
import { PATH } from '../../routes/CustomRoutes';
import flagEng from './../../assets/eng.png';
import flagThai from "./../../assets/thai.png";
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import { AutoCenter } from 'antd-mobile'

export default function PDPAModal(props) {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [width, setWidth] = React.useState(0);
  	const [local, setLocal] = React.useState("TH")
	const [isFSC, setFSC] = React.useState(true);
	const padRef = React.useRef(null);
	const signatureRef = React.useRef(null);
	const navigate = useNavigate();
	const { lessThanOrEqual } = useWindowDimensionContext();
	const isOnMobile = lessThanOrEqual('md');

	const handleClear = () => {
		if (signatureRef && signatureRef.current) {
			signatureRef.current.clear();
		}
	}

	const handleOK = async () => {
		let data = {}
		let signatureError = "Signature is empty!"
		if (signatureRef && signatureRef.current) {
			const ref = signatureRef.current;
			if (!ref.isEmpty()) {
				data["signature"] = ref.getTrimmedCanvas().toDataURL("image/png");
				signatureError = null;
			}
		} 

		if (signatureError) {
			setErrorMessages(signatureError);
			return;
		}
		setErrorMessages(null);
		props.onUpdated(data, isFSC);
  }

	React.useEffect(() => {
		// Initial width for signature pad
		if (props.open)
			if (padRef && (width === 0)) {
				setWidth(padRef.current.offsetWidth);

			setFSC(props.member ? props.member.is_fsc : true)
		} else {
			// Clear signature after close modal
			handleClear();
			setErrorMessages(null);
		}
	}, [props.open, padRef])

  return (

    <Modal
			closable= {false}
			maskClosable ={false}
			okText='Accept'
			keyboard={false}
			style={{top: 5}}
      width={isOnMobile? '100%':'80%'}
      open={props.open}
      onOk={handleOK}
      onCancel={props.onClose}
			footer={[
				<Button key={"1"} onClick={() => navigate(PATH.MEMBERS)}>{"Cancel"}</Button>,
				<Button key={"2"} type={"primary"} ghost onClick={handleClear}>{"Clear"}</Button>,
				<Button key={"3"} type={"primary"} onClick={handleOK}>{"Confirm"}</Button>
			]}
			>
      <Header title={(local === "TH") ? "นโยบายความเป็นส่วนตัวสำหรับลูกค้า" : "Privacy Policy for Customer"}></Header>
      <Row align='end' style={{margin: 8}}>
        <Image
					style={{marginRight : 8, borderRadius: 8, height: "24px", width: "auto"}}
          src={flagThai}
          preview={false}
          onClick={() => setLocal("TH")} />
        <Image
					style={{borderRadius: 8, height: "24px", width: "auto"}}
          src={flagEng}
          preview={false}
          onClick={() => setLocal("EN")} />
      </Row>
	  {isOnMobile ? (
		<div>
			<div style={{ width: 'auto', height: '500px', overflow: 'auto' }}>
			<AutoCenter >
			{(local === "TH") && (<PDPAModalTHView />)}

			{(local === "EN") && (<PDPAModalENView />)}	
			</AutoCenter>
			</div>
			<p style={{fontWeight: 'bold', marginTop:0}} >{"Signature"}</p>
				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
				}
				<div className="section-wrapper" ref={padRef}>
					<SignaturePad 
						penColor={"blue"} 
						ref={signatureRef} 
						canvasProps={{width: (width ? (width - 32) : 0), height: 120}}/>
				</div>
				
				<Checkbox 
					style={{marginTop: 8}}
					disabled={props.member ? true : false}
					checked={isFSC}
					onChange={(e) => setFSC(e.target.checked)}>สวนยางมาตรฐาน FSC</Checkbox>
		</div>
	  ):(
      <div style={{margin: 16}}>
        {/* PDPA Content */}
				{(local === "TH") && (<PDPAModalTHView />)}

				{(local === "EN") && (<PDPAModalENView />)}

				<br />
        <p style={{fontWeight: 'bold', marginTop:0}} >{"Signature"}</p>
				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
				}
				<div className="section-wrapper" ref={padRef}>
					<SignaturePad 
						penColor={"blue"} 
						ref={signatureRef} 
						canvasProps={{width: (width ? (width - 32) : 0), height: 120}}/>
				</div>
				
				<Checkbox 
					style={{marginTop: 8}}
					disabled={props.member ? true : false}
					checked={isFSC}
					onChange={(e) => setFSC(e.target.checked)}>สวนยางมาตรฐาน FSC</Checkbox>
      </div>)}
    </Modal>
  )

}

PDPAModal.defaultProps = {
  open: false,
	member: null,
	onUpdated: (sign, isFSC) => {},
  onClose: () => {}
}

PDPAModal.propTypes = {
  open: propTypes.bool,
	member: propTypes.string,
	onUpdated: propTypes.func,
  onClose: propTypes.func,
}