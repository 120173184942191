import React from 'react'
import propTypes from "prop-types";
import {  Alert, Col, DatePicker, Divider, Form, Input, Modal,  Row, theme, Checkbox, message, Select } from "antd";
import Header from '../common/Header';
import { PATCH, POST, DELETE } from '../../frameworks/HttpClient';
import { DATE_FORMAT } from '../../constants/string';
import { URL_MEMBER } from '../../constants/urls';
import UploadFile from '../common/UploadFile';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';



export default function FactoryModal (props) {
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [licenseDocuments, setLicenseDocuments] = React.useState([])
  const licenseDocumentRef = React.useRef(null);
  const { t, i18n } = useTranslation();

  const genderTH = [
    { value: 'นาย', label: t("prefix.mr") },
    { value: 'นาง', label: t("prefix.mrs") },
    { value: 'นางสาว', label: t("prefix.ms") },
    { value: 'เด็กชาย', label: t("prefix.boy") },
    { value: 'เด็กหญิง', label: t("prefix.girl") },
  ]

  const genderEN = [
    { value: 'นาย', label: t("prefix.mr") },
    { value: 'นาง', label: t("prefix.mrs") },
    { value: 'นางสาว', label: t("prefix.ms") },
  ]


	const {
		token: { colorPrimary, colorWarning },
	} = theme.useToken();

  const handleFactory = async () => {
    setErrorMessages(null);
    setLoading(true);
    let response = null
    try {
      const data = await form.validateFields();
      if (data["license_expire_date"])
        data["license_expire_date"] = data.license_expire_date.format(DATE_FORMAT);
      if (data['email'] === null)
        delete data["email"]

      const license_documents = licenseDocumentRef.current != null ? await licenseDocumentRef.current.getData() : []
      if (license_documents.length > 0) {
        data['license_document'] = license_documents[0].originFileObj
      } else {
        delete data['license_document']
      }

      if(props.target) {
        if (data["username"])
          delete data["username"]
        response = await PATCH(`${URL_MEMBER.FACTORY}${props.target.id}/`, data, true)
      }
      else {
        response = await POST(URL_MEMBER.FACTORY, data, true)
      }

      message.success(t("successfully_edited_factory_information"))
    } catch (error) {
      message.error(error.errorMessages)
    } finally {
      setLoading(false);
    }

    if (response)
      props.onUpdated()
  }

	React.useEffect(() => {
		if (props.open) {
      const data = props.target
      if (data) {
        form.setFieldsValue({
          ...data,
          license_expire_date: data.license_expire_date ? dayjs(data.license_expire_date) : null,
        })

        if(data.license_document) {
          setLicenseDocuments([{url: data.license_document}])
        }
      }
		} else {
      setErrorMessages(null);
      form.resetFields();
      setLicenseDocuments([]);
    }
	}, [props.open])
  

  return (
    <Modal
      width={760}
			okText={props.target ? t("edit") : t("add")}
			okButtonProps={{ style: {background: props.target ? colorWarning : colorPrimary}}}
      cancelText={t("cancel")}
			open={props.open}
      confirmLoading={loading}
			onOk={handleFactory}
			onCancel={props.onClose}>
        <Header fontSize={20} title={t("menu.factory")}></Header>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: "12px", textAlign: "left"}} />
        }

				<Form 
					form={form}
					style={{marginTop: 16}}
					layout="vertical"
          autoComplete="off">
          <Row gutter={16}>
            <Col md={16} sm={24} xs={24}>
              <Form.Item  
                name={"name"}
                label={t("factory_name")}
                rules={[{ required: true }]}>
                  <Input autoComplete="off"/>
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item
                name={"invoice_id"}
                label={t("tax_identification_number")}
                rules={[{ required: true }]}>
                <Input autoComplete="off" />
              </Form.Item>
            </Col>

            <Col md={16} sm={24} xs={24}>
              <Form.Item
                name={"name_en"}
                label={t("factory_name_English")}>
                <Input autoComplete="off" />
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item
                name={"short_name"}
                label={t("factory_abbreviation")}>
                <Input autoComplete="off" />
              </Form.Item>
            </Col>

            <Col md={24} sm={24} xs={24}>
              <Form.Item 
                name={"address"}
                label={t("farmers.address")}
                rules={[{ required: true }]}>
                <Input.TextArea />
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"license_number"}
                label={t("collecting_centers.license_number")}
                rules={[{ required: true }]}>
                  <Input autoComplete="off" />
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"license_expire_date"}
                label={t("collecting_centers.license_expire_date")}
                rules={[{ required: true }]}>
                  <DatePicker style={{ width: '100%' }} format={DATE_FORMAT}/>
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"fsc_number"}
                label={t("fsc_license_number")}>
                  <Input autoComplete="off"/>
              </Form.Item>
            </Col>

            <Col md={24} sm={24} xs={24}>
              <Form.Item 
                name={"license_address"}
                label={t("address_as_per_rubber_trading_license")}
                rules={[{ required: true }]}>
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
              name={"license_document"}
              label={t("upload_rubber_trading_license")}
              >
              <UploadFile
                className={"upload-list-inline"}
                style={{ background: colorPrimary, color: 'white' }}
                btnText={t("upload_rubber_trading_license")}
                maxCount={1}
                ref={licenseDocumentRef}
                dataList={licenseDocuments}
                onRemove={(data) => {
                  if(props.target) {
                    PATCH(`${URL_MEMBER.FACTORY}${props.target.id}/`, {license_document : null })
                  }
                }}
              />
              </Form.Item>
            </Col>

            <Divider orientation="left" style={{marginTop: 8, marginBottom: 8}}>{t("contact_information")}</Divider>
            <Col md={6} sm={24} xs={24}>
              <Form.Item  
                name={"title"}
                label={t("farmers.title")}
                rules={[{ required: true }]}>
                  <Select
                    style={{ width: '100%' }}
                    options={[
                      { value: 'นาย', label: t("prefix.mr") },
                      { value: 'นาง', label:  t("prefix.mrs")  },
                      { value: 'นางสาว', label:  t("prefix.ms")  },
                      { value: 'เด็กชาย', label:  t("prefix.boy")  },
                      { value: 'เด็กหญิง', label:  t("prefix.girl")  },
                    ]}
                  />
              </Form.Item>
            </Col>

            <Col md={9} sm={24} xs={24}>
              <Form.Item  
                name={"first_name"}
                label={t("user.first_name")}
                rules={[{ required: true }]}>
                  <Input autoComplete="off"/>
              </Form.Item>
            </Col>

            <Col md={9} sm={24} xs={24}>
              <Form.Item  
                name={"last_name"}
                label={t("user.last_name")}
                rules={[{ required: true }]}>
                  <Input autoComplete="off"/>
              </Form.Item>
            </Col>

            <Col md={12} sm={24} xs={24}>
              <Form.Item  
                name={"phone"}
                label={t("phone_number")}
                rules={[{ required: true }]}>
                  <Input autoComplete="off"/>
              </Form.Item>
            </Col>

            <Col md={12} sm={24} xs={24}>
              <Form.Item  
                name={"email"}
                label={t("email")}
                rules={[{ required: true }]}>
                  <Input autoComplete="off"/>
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"username"}
                label={t("profiles.username")}
                rules={[{ required: true }]}>
                  <Input autoComplete="off" disabled={props.target ? true : false}/>
              </Form.Item>
            </Col>

            {!props.target && (
              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"password"}
                  label={t("password")}
                  rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input.Password />
                </Form.Item>
              </Col>
            )}

            {!props.target && (
              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"confirm_password"}
                  label={t("confirm_password")}
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    { required: true, message: t("profiles.passwords_do_not_match") },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t("profiles.passwords_do_not_match")));
                      },
                    }),
                  ]}>
                    <Input.Password />
                </Form.Item>
              </Col>
            )}
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name="is_outsource"
                valuePropName="checked"
                initialValue={false}>
                <Checkbox>Agriac Outsource</Checkbox>
              </Form.Item>
            </Col>

          </Row>
        </Form>
    </Modal>
  )
}
FactoryModal.defaultProps = {
  open: false,
	target: null,
  onUpdated: () => {},
	onClose: () => { },
}

FactoryModal.propTypes = {
  open: propTypes.bool,
	target: propTypes.object,
  onUpdated: propTypes.func,
	onClose: propTypes.func,
}